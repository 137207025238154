.admission-content .header-sec {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    url("../img/bg-admission.jpg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  color: #fff;
}

.dropdown p {
  cursor: pointer;
}
