.form-style {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-style label {
  display: block;
  margin-bottom: 10px;
}
.form-style label > span {
  float: left;
  width: 100px;
  color: var(--blue);
  font-weight: bold;
  font-size: 13px;
  text-shadow: 1px 1px 1px #fff;
}
.form-style fieldset {
  margin: 0px 0px 10px 0px;
  border: 1px solid var(--blue);
  padding: 20px;
  background: var(--light);
}
.form-style fieldset legend {
  color: var(--blue);
  border-top: 1px solid var(--blue);
  border-left: 1px solid var(--blue);
  border-right: 1px solid var(--blue);
  background: var(--light);
  padding: 0px 8px 3px 8px;

  font-weight: bold;
  font-size: 1rem;
}
.form-style textarea {
  width: 350px;
  height: 100px;
}
.form-style input[type="text"],
.form-style input[type="date"],
.form-style input[type="datetime"],
.form-style input[type="number"],
.form-style input[type="search"],
.form-style input[type="time"],
.form-style input[type="url"],
.form-style input[type="email"],
.form-style select,
.form-style textarea {
  border: 1px solid var(--blueVarint);
  outline: none;
  color: #000;
  padding: 5px 8px 5px 8px;
  background: #fff;
  width: 100%;
}
.form-style input[type="submit"],
.form-style input[type="button"] {
  padding: 0.4rem 1rem;
  background-color: var(--orange);
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 1rem;
}
.required {
  color: red;
  font-weight: normal;
}
