footer {
  background-color: var(--blue);
  text-align: center;
  color: #fff;
}
footer a {
  color: #fff;
}
footer p {
  padding: 1rem 0;
}

hr {
  color: var(--light);
  margin: 0.5rem 0;
}
.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer-content img {
  width: 150px;
}

.footer-content a {
  display: inline-block;
  margin: 0 0.5rem;
}

.icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 798px) {
  .footer-content {
    flex-direction: column;
  }
}
