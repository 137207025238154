.about-hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)),
    url(../img/about-hero-sm.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
